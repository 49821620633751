<template>
  <div class="wrapp-product-help right-block-panel">
    <div class="right-block-content">
      <b-container fluid>
        <div class="right-block-content__intestation">
          <span class="content-title">{{ $t('helpProduct.need-help') }}</span>
        </div>
        <div class="right-block-content__body">
          <div class="product-help">
            <div class="product-help-block block-product">
              <b-row>
                <b-col sm="4" md="5">
                  <div class="block-product__media">
                    <image-gallery
                    :imgPath="productMedia.url"
                    :hasVideo="(productMedia.video_content) ? productMedia.video_content.video_url : ''"
                    size="MD"
                    :styleImage="{ maxWidth: '100%', height: 'auto' }"
                    :changeAble="true"/>
                  </div>
                </b-col>
                <b-col sm="8" md="7">
                  <div class="block-product__info">
                    <div class="product-name">
                      <p class="title" v-html="product.name" />
                    </div>
                    <div class="block-product__info__price">
                      <product-price :product="product" />
                      <span class="vat-included cl-gray" v-html="vatRules"></span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="product-help-block block-customer-services">
              <div class="customer-services__intro">
                <p>{{ $t('helpProduct.customer-services-hours')}}</p>
              </div>
              <div class="customer-services__cta">
                <div class="customer-services__cta_single">
                  <a :href="'tel:' + $t('customerCare.contact.tel')" class="bcm-link bcm-link-primary">{{ $t('help-call') }}</a>
                </div>
                <!-- <div class="customer-services__cta_single">
                  <a href="#" class="bcm-link bcm-link-primary">{{ $t('live-chat') }}</a>
                </div> -->
              </div>
            </div>
            <div class="product-help-block block-help-contact">
              <p class="form-title">{{ $t('helpProduct.how-we-can-help-you') }}</p>
              <b-form class="text-left" @submit.prevent="handleSubmit" novalidate>
                <div class="form-reason">
                  <b-row>
                    <b-col md="12">
                      <b-form-group id="groupContactReason">
                        <BaseSelect
                          v-model="form.reason"
                          name="contactreason"
                          v-validate="'required'"
                          :placeholder="'* ' + $t('customerCare.contact.contact_reason')"
                          :options="optionsReason"
                          :validations="[
                            {
                              condition: errors.has('contactreason'),
                              text: errors.first('contactreason')
                            }
                          ]"
                        >
                        </BaseSelect>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div class="form-info">
                  <div class="form-intro">{{ $t('helpProduct.fill-in-form') }}</div>
                  <b-row>
                    <b-col md="12">
                      <b-form-group id="groupTitle" >
                        <base-select
                          name="title"
                          v-validate="'required'"
                          v-model="form.prefix"
                          :options="prefixOptions"
                          :validations="[
                            {
                              condition: errors.has('title'),
                              text: errors.first('title')
                            }
                          ]"
                          :placeholder="'*' + $t('helpProduct.Title')"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group id="groupFirstname" >
                        <base-input
                          name="firstname"
                          v-validate="'required'"
                          v-model="form.firstname"
                          :placeholder="'*' + $t('helpProduct.FirstName')"
                          type="text"
                          :validations="[
                            {
                              condition: errors.has('firstname'),
                              text: errors.first('firstname')
                            }
                          ]"
                        >
                        </base-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group id="groupLastname" >
                        <base-input
                          name="lastname"
                          v-validate="'required'"
                          v-model="form.lastname"
                          :placeholder="'*' + $t('helpProduct.LastName')"
                          type="text"
                          :validations="[
                            {
                              condition: errors.has('lastname'),
                              text: errors.first('lastname')
                            }
                          ]"
                        >
                        </base-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group id="groupEmail">
                        <base-input
                          name="email"
                          v-validate="'required|email'"
                          v-model="form.email"
                          :placeholder="'*' + $t('helpProduct.Email')"
                          type="text"
                          :validations="[
                            {
                              condition: errors.has('email'),
                              text: errors.first('email')
                            }
                          ]"
                        >
                        </base-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group id="groupPhone">
                        <base-input
                          name="phone"
                          v-model="form.phone"
                          :placeholder="$t('helpProduct.Phone')"
                          type="text"
                          :validations="[
                            {
                              condition: errors.has('phone'),
                              text: errors.first('phone')
                            }
                          ]"
                        >
                        </base-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group id="groupCountry">
                        <base-select
                          name="country"
                          v-validate="'required'"
                          required
                          v-model="form.country"
                          :options="optionsCountries"
                          :validations="[
                            {
                              condition: errors.has('country'),
                              text: errors.first('country')
                            }
                          ]"
                          :placeholder="'*' + $t('helpProduct.Country')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group id="groupCity">
                        <base-input
                          name="city"
                          v-model="form.city"
                          :placeholder="$t('helpProduct.City')"
                          type="text"
                          :validations="[
                            {
                              condition: errors.has('city'),
                              text: errors.first('city')
                            }
                          ]"
                        >
                        </base-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group id="groupMessage">
                        <base-textarea
                          name="message"
                          v-validate="'required|min:5'"
                          required
                          v-model="form.message"
                          :validations="[
                            {
                              condition: errors.has('message'),
                              text: errors.first('message')
                            }
                          ]"
                          :placeholder="'*' + $t('helpProduct.Message')"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- terms -->
                  <b-form-group id="groupPrivacyAcceptance">
                    <base-check-box
                      name="privacyAcceptance"
                      v-validate="'required:true'"
                      :label="labelLinkPrivacy"
                      v-model="form.privacy"
                      :link="getLinkPrivacy"
                      :validations="[
                        {
                          condition: errors.has('privacyAcceptance'),
                          text: errors.first('privacyAcceptance')
                        }
                      ]"
                    />
                  </b-form-group>
                </div>
                <button type="submit" class="btn btn-primary full-width">{{ $t('send') }}</button>
              </b-form>
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import ImageGallery from '@/components/Product/Image.vue'
import ProductPrice from '@/components/Listing/ProductPrice'
import BaseInput from '@/components/Form/BaseInput'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import BaseSelect from '@/theme/components/Form/BaseSelect'
import BaseTextarea from '@/components/Form/BaseTextarea'
import { toast } from '@/modules/notifications'
import Config from '@/config'
import _ from 'lodash'
import { scrollTo } from '@/helpers/utils'
import CustomerService from '@/services/CustomerService'
import Logger from '@/services/Logger'
import { pathTranslate } from '@/helpers/routeHelpers'

const modelForm = {
  reason: null,
  prefix: '',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  country: '',
  city: '',
  message: '',
  privacy: false,
  uploadfile: []
}

export default {
  name: 'ProductHelp',
  inject: ['$validator'],
  data () {
    return {
      form: { ...modelForm }
    }
  },
  computed: {
    vatRules () {
      let content = this.$store.getters['cms/getContent'](Config.Theme.blockTaxRuleID)
      content = content.replace('{taxRate}', `${this.product.tax_rate}%`)
      return content
    },
    product () {
      return this.$store.state.product.current
    },
    optionsCountries () {
      return this.$store.state.checkout.countries.map(country => ({
        value: country.id,
        label: country.full_name_locale
      }))
    },
    optionsReason () {
      const _this = this
      const newOpt = []
      Config.Theme.contactUs.reason.map(item => {
        if (!item.mandatory) {
          newOpt.push({
            ...item,
            label: _this.$t(`customerCare.contact.reasons.option.${item.value}`)
          })
        }
      })
      return newOpt
    },
    prefixOptions () {
      const _this = this
      return Config.Theme.forms.prefixValues.map(item => _this.$t('form.prefix.' + item))
    },
    productMedia () {
      // push all product image
      let media = this.product.image
      let video = _.find(this.product.media_gallery, 'video_content')
      if (video) {
        media = { ...video, ...media }
      }
      return media
    },
    labelLinkPrivacy () {
      return this.$t('customerCare.contact.privacyWithoutLink')
    },
    getLinkPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'genericTerms', params: { slug: pathTranslate({ path: 'privacy-policy' }), lang: this.$store.state.ui.storeViewCode } }).href
      return linkPrivacy
    }
  },
  methods: {
    openModalTerms ({ open, pageID }) {
      this.modalTerms = {
        open,
        pageID
      }
    },
    clearForm () {
      this.form.firstname = this.form.lastname = this.form.email = this.country = this.message = this.ordernumber = ''
      this.form.privacy = false
      this.form.reason = null
      this.form.uploadfile = []
      this.$validator.reset()
    },
    renderContent (params, prod) {
      return `<div><strong>${this.$t('customerCare.contact.contact_reason')}:</strong> <span>${params.reason.label}</span></div>
        <div><strong>SKU:</strong> <span>${prod.sku}</span></div>
        <div><strong>${this.$t('address.Title')}:</strong> <span>${this.$t('form.prefix.' + params.prefix)}</span></div>
        <div><strong>${this.$t('address.FirstName')}:</strong> <span>${params.firstname}</span></div>
        <div><strong>${this.$t('address.LastName')}:</strong> <span>${params.lastname}</span></div>
        <div><strong>${this.$t('address.Email')}:</strong> <span>${params.email}</span></div>
        <div><strong>${this.$t('address.PhoneNumber')}:</strong> <span>${params.phone}</span></div>
        <div><strong>${this.$t('address.Country')}:</strong> <span>${params.country.label}</span></div>
        <div><strong>${this.$t('address.City')}:</strong> <span>${params.city}</span></div>
        <div><strong>${this.$t('helpProduct.Message')}:</strong> <span>${params.message}</span></div>`
    },
    handleSubmit () {
      const _this = this
      _this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          _this.$store.commit('ui/showLoader')
          return CustomerService.sendCustomerCare({
            params: {
              contentHTML: _this.renderContent(_this.form, _this.getProdData()),
              email_to: _this.form.reason.to,
              email_cc: _this.form.reason.cc,
              ..._this.getProdData()
            },
            storeViewCode: _this.$store.state.ui.storeViewCode
          })
            .then(res => {
              if (res.data && res.data.success) {
                toast.success(_this.$t('Request successful'))
                _this.clearForm()
              } else {
                Logger.error(res)
                res.data && res.data.error_messages && toast.error(_this.$t(res.data.error_messages))
              }
            })
            .catch(err => {
              Logger.error(err)
              const data = err.response.data
              if (data && data.message) {
                toast.error(_this.$t(data.message))
              } else {
                toast.error(_this.$t('GenericError'))
              }
            })
            .finally(() => {
              _this.$store.commit('ui/hideLoader')
            })
        } else {
          scrollTo({
            className: 'is-invalid'
          })
        }
      })
    },
    getProdData () {
      const prod = this.$store.state.product.tmpProdSelected.length === 1 ? this.$store.state.product.tmpProdSelected[0] : this.$store.state.product.current
      return {
        name: prod.name,
        sku: prod.sku
      }
    }
  },
  mounted () {
    this.$store.dispatch('checkout/loadCountries')
  },
  components: {
    ImageGallery,
    ProductPrice,
    BaseInput,
    BaseSelect,
    BaseCheckBox,
    BaseTextarea
  }
}
</script>
<style lang="scss">
  .wrapp-product-help{
    .block-product__info__price .cl-gray.vat-included > p{
      font-size: .75rem!important;
      margin-top: 10px;
    }
    .product-price_original,
    .product-price_discount{
      display: none
    }
  }
</style>
